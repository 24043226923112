import { Controller } from '@hotwired/stimulus';
import { toggle } from 'el-transition';

// Connects to data-controller="card-dropdown"
export default class extends Controller {
  static targets = ['subcontent'];

  toggleSubcontent(e) {
    if (this.subcontentTarget.contains(e.target)) {
      return;
    }

    if (e.target.nodeName !== 'svg' && e.target.nodeName !== 'path') {
      toggle(this.subcontentTarget);
    }
  }
}
